.theme-base-08 {
	.sidebar {
		background-color: #202020;
	}
	.content a {
		color: #202020;
	}
	.related-posts li a:hover {
		color: #202020;
	}
}